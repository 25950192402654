export const Button = ({ children, sectionId, className }) => {
  const handleClick = (e) => {
    e.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <button onClick={handleClick} className={`rounded-full px-5 py-1.5 text-sm font-lexend shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${className}`}>
      {children}
    </button>
  );
};
  
