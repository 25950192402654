import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { Button } from "../general/Button";

export default function Navigation({ siteConfig, isBlur }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const navigationContent = siteConfig?.content?.navigazione[0];
  const textColorClass = isBlur ? "text-black" : "text-white";
  const colorLogo = isBlur
    ? navigationContent?.logo[0]?.filename
    : navigationContent?.logo[1]?.filename;
  //  navigationContent
  const titleToSectionIdMap = {
    "About us": "about-us",
    Mission: "mission",
    Products: "products",
    Strengths: "strengths",
    "Contact-us": "contact-us",
  };
  const navigation = Object.keys(navigationContent || {})
    .map((key) => {
      if (key.startsWith("title")) {
        const title = navigationContent[key];
        const path = titleToSectionIdMap[title];

        return { name: title, to: path };
      }

      return null;
    })
    .filter((item) => item);

  return (
    <header
      className={
        isBlur
          ? "sticky top-0 z-40 w-full flex-none duration-500 lg:z-50  bg-white"
          : "sticky  top-0 z-40 w-full flex-none duration-500 lg:z-50 "
      }
    >
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <Link to={"/"} className="-m-1.5 p-1.5 ">
            <span className="sr-only">Your Company</span>
            <img className="h-8 w-auto " src={colorLogo} alt="" />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon
              className={`h-6 w-6 ${textColorClass}`}
              aria-hidden="true"
            />
          </button>
        </div>

        <div className="hidden  lg:flex lg:items-center">
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={`/#${item.to}`}
                onClick={(e) => {
                  e.preventDefault();
                  setMobileMenuOpen(false);
                  document
                    .getElementById(item.to)
                    .scrollIntoView({ behavior: "smooth" });
                }}
                className={`inline-flex items-center px-1 pt-1 text-sm font-lexend ${textColorClass}`}
              >
                {item.name}
              </Link>
            ))}
            <Button sectionId="contact-us" className="bg-blue text-white">
              {navigationContent?.button}
            </Button>
          </div>
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to={"/"} className="-m-1.5 p-1.5">
              <img
                className="h-8 w-auto"
                src={navigationContent?.logo[0]?.filename}
                alt=""
              />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className=" py-6">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={`/#${item.to}`}
                    onClick={(e) => {
                      e.preventDefault();
                      setMobileMenuOpen(false);
                      document
                        .getElementById(item.to)
                        .scrollIntoView({ behavior: "smooth" });
                    }}
                    className="block rounded-lg px-3 py-2 text-base font-lexend leading-7 text-wite hover:bg-gray-50"
                  >
                    {item.name}
                  </Link>
                ))}
                <div className="flex-1 border-b mt-3 border-l gray-500"></div>
                <div className="py-6">
                  <Link
                    to={`/#contact-us`}
                    onClick={(e) => {
                      e.preventDefault();
                      setMobileMenuOpen(false);
                      document
                        .getElementById("contact-us")
                        .scrollIntoView({ behavior: "smooth" });
                    }}
                    className="font-lexend text-lg"
                  >
                    {navigationContent?.button}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
