import React, { useState, useEffect } from 'react';
import Navigation from "./navigation";
import Footer from "./footer";

const Layout = ({bg = 'bg-solitude-100 ', siteConfig,storyBlok, children, isDark = true, isFixed = false}) => {

    const [isActive, setIsActive] = useState(false);

    const handleScroll = () => {
        if(!isFixed){
            if (window.scrollY > 50) {
                setIsActive(true);
            } else {
                setIsActive(false);
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
        // eslint-disable-next-line
    }, []);


    return (
        <div className={bg + " scroll-smooth "}>
            <Navigation siteConfig={siteConfig} isFixed={isFixed} isBlur={isActive} isDark={isActive ? isActive : isDark} />
                {children}
            <Footer siteConfig={siteConfig} isDark={isDark} storyBlok={storyBlok} />
        </div>
    );
}


export default Layout;
