import { motion } from "framer-motion";
export default function MissionSection({ siteConfig, storyBlok }) {
  const MissionSection = siteConfig?.content?.MissionSection[0];
  const animationText = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'tween',
        ease: 'easeInOut', 
        duration: 0.8, 
      },
    },
  };
  return (
    <div id="mission" className="relative isolate overflow-hidden px-6 py-24 ">
      
      <div className="mx-auto max-w-3xl text-center">
        <motion.h2 variants={animationText}
                  initial="hidden"
                  whileInView="show"
                  className="text-3xl md:text-4xl font-lexend tracking-tight text-white ">
          {MissionSection?.Title}
        </motion.h2>
        <motion.div 
          variants={animationText}
          initial="hidden"
          whileInView="show"
          className="text-base md:text-lg mt-4 mb-5 font-sans text-white">
          <div
            dangerouslySetInnerHTML={{
              __html: storyBlok.richTextResolver.render(
                MissionSection?.Text1 ?? ""
              ),
            }}
          />
          <div
            className="pt-4"
            dangerouslySetInnerHTML={{
              __html: storyBlok.richTextResolver.render(
                MissionSection?.Text2 ?? ""
              ),
            }}
          />
        </motion.div>
      </div>
      <img
        src={MissionSection?.image?.filename}
        alt=""
        className="absolute inset-0 -z-10 h-full w-full object-cover object-center"
      />
      <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-20 -z-10" />
    </div>
  );
}
