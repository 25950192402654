import { useEffect, useState } from "react";
import video from "../../resource/video/video.mp4";
import videoBg from "../../resource/image/video-bg.png";
import { Button } from "./Button";
import { motion } from "framer-motion";
import VideoPopup from "./video-popup";



export default function HomeHeader({ siteConfig }) {
  const homeHeader = siteConfig?.content?.HomeHeader[0];
  let [isOpen, setIsOpen] = useState(false)

  const content = Array.from({ length: 3 }, (_, i) => ({
    title: homeHeader?.[`Title${i + 1}`] ?? "",
    text: homeHeader?.[`Text${i + 1}`] ?? "",
  }));

  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTextIndex(
        (currentTextIndex) => (currentTextIndex + 1) % content.length
      );
    }, 3000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, []);


  const animationText = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'tween',
        ease: 'easeInOut',
        duration: 0.8,
      },
    },
  };


  return (
    <>
      <div  className="relative  isolate overflow-hidden pt-28  mx-auto lg:gap-x-8 lg:px-8  lg:py-40 -mt-24">
        <div className="mx-auto max-w-7xl  pt-10  lg:grid lg:grid-cols-2 lg:gap-x-8 lg:px-8  ">

          <div className="px-6 lg:px-0 lg:pt-4">
            <div className="mx-auto max-w-2xl h-96">
              <div className="max-w-lg">
                <motion.h1
                    variants={animationText}
                    initial="hidden"
                    whileInView="show"
                    className="max-w-2xl pb-7 text-3xl md:text-4xl font-lexend tracking-tight text-white lg:col-span-2 xl:col-auto">
                  {homeHeader?.Headline ?? ""}
                </motion.h1>
                <motion.div
                    variants={animationText}
                    initial="hidden"
                    whileInView="show"
                    className="grid grid-rows-1 gap-6 max-w-xl xl:col-end-1 xl:row-start-1 mb-3 ">
                  <div className="row-span-2 ">
                    <motion.h2  variants={animationText}
                                initial="hidden"
                                whileInView="show" className="text-xl md:text-2xl font-lexend tracking-tight text-light-blue">
                      {content[currentTextIndex]?.title ?? ""}
                    </motion.h2>
                    <motion.p  variants={animationText}
                               initial="hidden"
                               whileInView="show" className="text-base md:text-lg font-sans leading-8 text-white">
                      {content[currentTextIndex]?.text ?? ""}
                    </motion.p>
                  </div>
                </motion.div>


              </div>
              <div className="row-span-1 ">
                <Button
                    sectionId="contact-us" className="mt-10 bg-white text-blue  "
                >
                  {homeHeader?.Button ?? ""}
                </Button>
              </div>
            </div>
          </div>

          <div className=" md:mx-auto md:max-w-2xl lg:mx-0 lg:mt-0 lg:w-screen">
            <div className="sm:pt-4 sm:pb-4">

              <div className="relative  flex-auto lg:ml-auto  lg:flex-none lg:self-end">
                  <img
                      src={videoBg}
                      alt=""
                      className="h-full w-full object-cover shadow-lg rounded-3xl p-4 md:p-0"
                  />

                  <span className="absolute inset-0 w-full h-full flex items-center justify-center cursor-pointer" aria-hidden="true" onClick={() => {setIsOpen(true)}}>
                      <svg className="h-20 w-20 text-sky-500 hover:text-sky-800"  fill="currentColor" viewBox="0 0 84 84">
                        <circle opacity="0.9" cx={42} cy={42} r={42} fill="white" />
                        <path d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z" />
                      </svg>
                    </span>
              </div>

            </div>
          </div>
        </div>

        <img
            src={homeHeader?.image?.filename ?? ""}
            alt=""
            className="absolute inset-0 -z-10 h-full w-full object-cover object-center"
        />
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-30 -z-10" />
      </div>

      <VideoPopup
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          video={video}
      />

    </>





  );
}
