import React from "react";

const InputRadio = ({label,  name, errors, warning = '', defaultChecked = false, input, value}) => {

    return (
        <label className="flex items-center space-x-2">
            <input type="radio"
                   name={name}
                   className="form-radio border-blue text-black focus:ring-blue"
                   defaultChecked={defaultChecked}
                   value={value}
                   {...input}
            />
            <div className="text-blue font-sans">
                <span>{label}</span>
            </div>
            <div className="absolute inset-0 border-2 border-transparent peer-checked:border-indigo-800 rounded pointer-events-none" aria-hidden="true" />
            {errors && errors.type === 'required' && (  <p className="mt-2 text-sm text-red-600">{warning}</p>)}
        </label>
    )
};
export default InputRadio;
