import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './resource/style/index.css';
import Main from "./routes/main";
import reportWebVitals from './reportWebVitals';
import "./i18n";
import {BrowserRouter} from "react-router-dom";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Suspense fallback={<></>}>
                <Main />
            </Suspense>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
