import { motion } from "framer-motion";
export default function FeatureSection({ siteConfig, storyBlok }) {
  const FeatureSection = siteConfig?.content?.FeatureSection[0];
  const Value = FeatureSection?.Table;
  const stats =
    Value && Value.tbody[0]?.body.length === Value.thead.length
      ? Value.tbody[0].body.map((item, index) => ({
          id: index + 1,
          name: item.value,
          value: Value.thead[index]?.value,
        }))
      : [];
  const animationText = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        type: "tween",
        ease: "easeInOut",
        duration: 0.8,
      },
    },
  };
  return (
    <div id="about-us" className="bg-white py-24">
      <div className="mx-auto max-w-3xl px-6 lg:px-8">
        <div className="text-center">
          <motion.h2
            variants={animationText}
            initial="hidden"
            whileInView="show"
            className="text-3xl md:text-4xl font-lexend tracking-tight text-black "
          >
            {FeatureSection?.Headline}
          </motion.h2>
          <motion.div
            variants={animationText}
            initial="hidden"
            whileInView="show"
            className="text-base md:text-lg mt-4 mb-5 font-sans text-black"
          >
            <div
              dangerouslySetInnerHTML={{
                __html: storyBlok.richTextResolver.render(
                  FeatureSection?.Text1 ?? ""
                ),
              }}
            />
            <div
              className="pt-4"
              dangerouslySetInnerHTML={{
                __html: storyBlok.richTextResolver.render(
                  FeatureSection?.Text2 ?? ""
                ),
              }}
            />
          </motion.div>
        </div>

        <div className="flex flex-col items-center justify-center mx-auto space-y-4 sm:space-y-0 sm:flex-row sm:space-x-4">
          <div className="flex-1 border-b border-r border-blue "></div>
          <div className="text-center">
            <motion.h2
              variants={animationText}
              initial="hidden"
              whileInView="show"
              className="mb-2 font-lexend font-semibold text-blue text-xl md:text-2xl"
            >
              {FeatureSection?.Title}
            </motion.h2>
            <motion.p
              variants={animationText}
              initial="hidden"
              whileInView="show"
              className="text-base md:text-lg mx-4 font-sans text-grey "
            >
              {FeatureSection?.Text3}
            </motion.p>
          </div>
          <div className="flex-1 border-b border-l border-blue"></div>
        </div>

        <div className="relative mt-10">
          <motion.img
            variants={animationText}
            initial="hidden"
            whileInView="show"
            src={FeatureSection?.image?.filename}
            alt=""
            className="mx-auto my-6"
          />
        </div>

        <motion.dl
          variants={animationText}
          initial="hidden"
          whileInView="show"
          className="grid ms:grid-cols-1 grid-cols-3 gap-3 mx-auto max-w-max"
        >
          {stats.map((stat) => (
  <div
    key={stat.id}
    className="w-full px-6 py-3 me-6 flex flex-col text-center bg-sky-blue p-2 rounded-2xl mt-5"
  >
    <dt className="text-xs lg:text-2 font-lexend text-grey">{stat.name}</dt>
    <dd className="order-first text-2xl lg:text-3xl font-lexend font-bold tracking-tight text-blue">
      {stat.value}
    </dd>
  </div>
))}
</motion.dl>
      </div>
    </div>
  );
}
