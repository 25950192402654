import { Button } from "./Button";
import { motion } from "framer-motion";
export default function ContactSection({ siteConfig }) {
  const animationText = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        type: "tween",
        ease: "easeInOut",
        duration: 0.8,
      },
    },
  };
  const ContactSection = siteConfig?.content?.ContactSection[0];
  return (
    <div className="relative isolate overflow-hidden  py-24  ">
      <img
        src={ContactSection?.image?.filename}
        alt=""
        className="absolute inset-0 -z-10 w-full h-full object-cover"
      />
      <div className="text-center">
        <div className="mx-auto sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="mx-auto max-w-xs sm:max-w-sm md:max-w-md lg:max-w-3xl lg:text-center">
            <motion.h2
              variants={animationText}
              initial="hidden"
              whileInView="show"
              className="text-xl md:text-2xl font-lexend tracking-tight text-white"
            >
              {ContactSection?.Title}
            </motion.h2>
            <motion.p
              variants={animationText}
              initial="hidden"
              whileInView="show"
              className="mt-2 text-base md:text-lg font-sans leading-8 text-white"
            >
              {ContactSection?.Text}
            </motion.p>
            <div className="mt-4 flex items-center justify-center gap-x-6">
              <Button sectionId="contact-us" className="bg-white text-blue">
                {ContactSection?.Button}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
