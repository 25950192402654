import React, {useEffect, useState} from "react";
import { Route, Routes } from "react-router";
import Homepage from "./pages/homepage";
import {useTranslation} from "react-i18next";
import StoryblokClient from 'storyblok-js-client';







function Main() {
    const { i18n } = useTranslation();
    const language = i18n.language.includes("en") ? "en" : "it";

    const [siteConfig, setSiteConfig] = useState(null);
    const [isLoading, setLoading] = useState(true);

    // eslint-disable-next-line
    const storyBlok = new StoryblokClient({
        accessToken:'mhEuXeJN6xiqV0hRuqcHkgtt',
    });


    useEffect(() => {
        if(!siteConfig){
            storyBlok.get(`cdn/stories/config`, {"language": language})
                  .then(response => {
                      setSiteConfig(response.data.story);
                      setLoading(false);
                      console.log(response)
                  }).catch(error => {
                      console.error('Error fetching story:', error);
                  });
              }
          }, [storyBlok, siteConfig,language]);

    if (isLoading) {
      return <></>;
    }

    return (
      <Routes>
        <Route path="/" element={<Homepage siteConfig={siteConfig} storyBlok={storyBlok} language={language} />} />
      </Routes>
  );
}

export default Main;
