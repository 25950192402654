import { motion } from "framer-motion";
import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {emailRegex} from "../../constants/regex";
import InputSubmit from "./FormSubmit";
import InputText from "./InputText";
import InputRadio from "./InputRadio";
export default function ContactForm({ siteConfig, storyBlok }) {


  const ContactForm = siteConfig?.content?.ContactForm[0];
  const cards = [
    {
      title: ContactForm?.cards[0]?.label,
      email: ContactForm?.cards[0]?.text,
      text: ContactForm?.cards[0]?.button,
    },
    {
      title: ContactForm?.cards[1]?.text,
      email: ContactForm?.cards[1]?.button,
    },
    {
      title: ContactForm?.cards[2]?.label,

      text: ContactForm?.cards[2]?.text,
      email: ContactForm?.cards[2]?.button,
    },
  ];

  const notificationMethods = [
    { id: "mr", title: ContactForm?.title3 },
    { id: "mrs", title: ContactForm?.title4 },
    { id: "Client", title: ContactForm?.title1 },
    { id: "Supplied", title: ContactForm?.title2 },
  ];
  const animationText = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        type: "tween",
        ease: "easeInOut",
        duration: 0.8,
      },
    },
  };

  const [exception, setException] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isSendLoading, setSendLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setSuccess(false);
    setException(false);
    setSendLoading(true);

    const mail = {
      service_id: "service_beyk31m",
      template_id: "template_u98yc9k",
      user_id: "HhmWYkgnd8AeSuxop",
      template_params: {
        fullname: data.fullname,
        country: data.country,
        email: data.email,
        phone_number: data.phone_number,
        message: data.message,
        company_type: data.company_type,
        user_type: data.user_type,
      },
    };

    axios
      .post("https://api.emailjs.com/api/v1.0/email/send", mail)
      .then(function (response) {
        setSuccess(true);
      })
      .catch(function (error) {
         console.log(error);
        setException(true);
      })
      .finally(function () {
        setSendLoading(false);
      });
  };
  return (
    <div id="contact-us" className="isolate bg-white pb-24 px-6 lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
        <motion.h2
          variants={animationText}
          initial="hidden"
          whileInView="show"
          className="text-3xl md:text-4xl tracking-tight text-gray-900"
        >
          {ContactForm?.Headline}
        </motion.h2>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="mx-auto mt-16 max-w-xl lg:max-w-5xl sm:mt-20">
        <motion.div
          variants={animationText}
          initial="hidden"
          whileInView="show"
          className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 "
        >
          <div>
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-start space-y-4 sm:space-y-0 sm:space-x-6">
              <span className="font-lexend text-black">
                {ContactForm?.span1}
              </span>
              <div className="flex space-x-2">

                {notificationMethods.filter((method) => method.id === "Client" || method.id === "Supplied")
                    .map((method) => (
                        <InputRadio
                            key={method.id}
                            name={'company_type'}
                            errors={errors.company_type}
                            input={{...register("company_type", { required: false })}}
                            label={method.title}
                            value={method.id}
                        />

                    ))}
              </div>






            </div>


            <div className="mt-2.5">
              <InputText
                  id={"fullname"}
                  type={"text"}
                  errors={errors.fullname}
                  input={{ ...register("fullname", { required: true,
                      minLength: {
                        value: 6,
                        message: "Last name must be at least 6 characters",
                      }
                  }) }}
                  label={''}
                  placeholder={'Full name*'}
              />
            </div>




          </div>
          <div>
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-start space-y-4 sm:space-y-0 sm:space-x-6">
              <span className="font-lexend text-gray-700">
                {ContactForm?.span2}
              </span>
              <div className="flex space-x-2">
                {notificationMethods
                  .filter((method) => method.id === "mr" || method.id === "mrs")
                  .map((method) => (
                      <InputRadio
                          key={method.id}
                          name={'user_type'}
                          errors={errors.user_type}
                          input={{...register("user_type", { required: false })}}
                          label={method.title}
                          value={method.id}
                      />
                  ))}
              </div>
            </div>


            <div className="mt-2.5">
              <InputText
                  id={"country"}
                  type={"text"}
                  errors={errors.country}
                  input={{ ...register("country", { required: true,
                      minLength: {
                        value: 6,
                        message: "Last name must be at least 6 characters",
                      }
                    }) }}
                  label={''}
                  placeholder={'Country*'}
              />
            </div>



          </div>


          <div>
            <div className="mt-2.5">

              <InputText
                  id={"email"}
                  type={"text"}
                  errors={errors.email}
                  input={{ ...register("email", { required: true,
                      minLength: {
                        value: 6,
                        message: "Last name must be at least 6 characters",
                      },
                      pattern: {
                        value: emailRegex,
                        message: "Entered value does not match email format",
                      }
                    }) }}
                  label={''}
                  placeholder={'Email*'}
              />
            </div>
          </div>


          <div>

            <div className="mt-2.5">

              <InputText
                  id={"phone_number"}
                  type={"text"}
                  errors={errors.phone_number}
                  input={{ ...register("phone_number", { required: true,
                      minLength: {
                        value: 6,
                        message: "Last name must be at least 6 characters",
                      }
                    }) }}
                  label={''}
                  placeholder={'Phone number*'}
              />
            </div>



          </div>


          <div className="sm:col-span-2">
            <div className="mt-2.5">

              <InputText
                  id={"message"}
                  type={"textarea"}
                  errors={errors.message}
                  input={{ ...register("message", { required: true,
                      minLength: {
                        value: 6,
                        message: "Last name must be at least 6 characters",
                      }
                    }) }}
                  label={''}
                  placeholder={'Message*'}
              />
            </div>
          </div>



        </motion.div>
        <motion.div
          variants={animationText}
          initial="hidden"
          whileInView="show"
          className="pt-4 relative flex items-start"
        >
          <div className="flex h-6 items-center">
            <input
              id="candidates"
              aria-describedby="candidates-description"
              name="candidates"
              type="checkbox"
              className="h-4 w-4 border-blue text-blue focus:ring-blue"
            />
          </div>

          <div className="text-sm font-lexend ml-3">
            <div
              id="candidates-description"
              className=" text-grey"
              dangerouslySetInnerHTML={{
                __html: storyBlok.richTextResolver.render(
                  ContactForm?.text ?? ""
                ),
              }}
            />
          </div>
        </motion.div>

        {
            exception && (
                <div className="mt-5">
                  <div className={`bg-red-50 border-l-4 border-red-400 p-4`}>
                    <div className="flex">
                      <div className="ml-3">
                        <p className={`text-sm text-red-700`}>
                          Error sending message, please try again later.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
            )
        }


        {
            success && (
                <div className={'mt-5 mb-5'}>
                  <div className={`bg-green-50 border-l-4 border-green-400 p-4`}>
                    <div className="flex">
                      <div className="ml-3">
                        <p className={`text-sm text-green-700`}>
                          Message sent successfully, you will be contacted by our team as soon as possible!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
            )
        }


        <div className="relative my-8  flex justify-between">



          <InputSubmit
              label={ContactForm?.button1}
              isLoading={isSendLoading}
          />


          <a href={'https://api.whatsapp.com/send/?phone=393928572937'} target='_blank' rel="noreferrer" className="rounded-full px-5 py-1.5 text-sm font-lexend shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-light-blue rounded-md">
            {ContactForm?.button2?.title}
          </a>
          <img
            src={ContactForm?.button2?.filename}
            alt=""
            className="absolute right-5 transform translate-x-1/2 w-12 h-12 mx-3"
            style={{
              top: "-50%",
              transform: "translateY(-30%) translateX(100%)",
            }}
          />
        </div>
      </form>
      <div className="relative overflow-hidden py-24">
        <div className="mx-auto max-w-7xl px-3 lg:px-8">
          <motion.div
            variants={animationText}
            initial="hidden"
            whileInView="show"
            className="grid max-w-2xl mx-auto grid-cols-1 gap-4 lg:max-w-none lg:grid-cols-3 lg:gap-8"
          >
            {cards.map((card, index) => (
              <div
                key={index}
                className="flex flex-col items-center border-4 border-sky-blue rounded-xl p-10"
              >
                <h3
                  className={`${
                    index === 2
                      ? "font-lexend text-black"
                      : "fon-lexend font-semibold text-blue"
                  }`}
                >
                  {card.title}
                </h3>
                {card.text && (
                  <p className=" text-black font-lexend">{card.text}</p>
                )}
                {card.email && (
                  <p
                    className={`${
                      index === 2
                        ? "font-lexend font-semibold  text-blue"
                        : "font-lexend text-black"
                    }`}
                  >
                    {card.email}
                  </p>
                )}
              </div>
            ))}
          </motion.div>
        </div>
      </div>
    </div>
  );
}
