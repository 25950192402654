import { motion } from "framer-motion";
import {Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Mousewheel, Pagination } from "swiper/modules";


import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { useEffect, useRef } from "react";

const customSwiper = { overflow: "visible" };


export default function StrengthSection({ siteConfig, storyBlok }) {
  const swiperRef = useRef(null);
  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.swiper.autoplay.start();
    }
  }, []);
  const StrengthSection = siteConfig?.content?.StrengthSection[0];
  const products = StrengthSection?.images.map((image, index) => ({
    id: index + 1,
    imageSrc: image.filename,
    title: image.title,
    description: image.alt,
  }));
  const animationText = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'tween',
        ease: 'easeInOut',
        duration: 0.8,
      },
    },
  };


  return (
    <div
      id="strengths"
      className="relative overflow-hidden bg-blue-600 text-white px-6 py-24  lg:px-8 smoke-effect-blue"
    >
      <img
        src={StrengthSection?.Image?.filename}
        className="absolute inset-0 -z-0 h-full w-full object-cover"
        alt="Background"
      />
      <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-20 -z-0" />

      <div className="mx-auto max-w-3xl relative z-10 flex flex-col items-center text-center space-y-6">
        <motion.h2
                  variants={animationText}
                  initial="hidden"
                  whileInView="show"
                  className="text-3xl md:text-4xl font-lexend font-bold">
                  {StrengthSection?.Headline}
                  </motion.h2>
        <motion.div
            variants={animationText}
            initial="hidden"
            whileInView="show"
            className="text-base md:text-lg mt-4 mb-5 font-sans text-white leading-normal">
          <div
            className="pb-4"
            dangerouslySetInnerHTML={{
              __html: storyBlok.richTextResolver.render(
                StrengthSection?.Text1 ?? ""
              ),
            }}
          />
        </motion.div>
      </div>
      <>
      <Swiper
        slidesPerView={3}
        centeredSlides={true}
        spaceBetween={30}
        grabCursor={true}
        pagination={{ clickable: true }}
        modules={[Pagination, Mousewheel, Autoplay]}
        mousewheel={true}
        className="mySwiper max-w-4xl"
        style={customSwiper}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
          waitForTransition: false
        }} 
          breakpoints={{
          
            320: {
              slidesPerView: 1, 
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 2, 
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
          }}
        >
          <div>
            {products?.map((product) => (
              <SwiperSlide
                key={product.id}
                >
                {({ isActive }) => (
                  <div className="my-12 bg-white text-gray-900 rounded-2xl ml-auto mr-auto w-9/12 lg:w-fit lg:mx-0"
                  style={{ paddingTop: "4rem", minHeight:  "300px"}}>
                    <div className="group flex justify-center -mt-16 ">
                      <img
                          src={product.imageSrc}
                          alt=""
                          className={"rounded-full h-28 w-28 object-cover"}
                          style={{ transform: "translateY(-20%)" }}
                      />
                    </div>
                    <motion.div
                      variants={animationText}
                      initial="hidden"
                      whileInView="show"
                       className="px-8 pb-4 text-center ">
                      <h3 className="text-xl pb-2 font-lexend ">
                        {product.title}
                      </h3>
                      <p className="pt-3 text-xs font-sens leading-normal">
                        {product.description}
                        </p>
                    </motion.div>
                  </div>
                )}

              </SwiperSlide>
            ))}
          </div>
        </Swiper>
      </>
    </div>
  );
}
