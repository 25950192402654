import { useEffect, useState } from "react";
import MissionSection from "../../components/general/MissionSection";
import ProductSection from "../../components/general/ProductSection";
import FeatureSection from "../../components/general/FeatureSection";
import StrengthSection from "../../components/general/StrengthSection";
import ContactSection from "../../components/general/ContactSection";
import BrandSection from "../../components/general/BrandSection";
import ContactForm from "../../components/general/ContactForm";
import Layout from "../../components/structure/layout";
import HomeHeader from "../../components/general/HomeHeader";

const Homepage = ({ siteConfig, storyBlok, language }) => {
  const [homePageData, setHomePageData] = useState(null);

  useEffect(() => {
    if (!homePageData) {
      storyBlok
        .get(`cdn/stories/homepage`, { language: language })
        .then((response) => {
          setHomePageData(response.data.story);
          console.log(response);
        })
        .catch((error) => {
          console.error("Error fetching story:", error);
        });
    }
      // eslint-disable-next-line
  }, [storyBlok, homePageData]);

  return (

    <Layout siteConfig={siteConfig} storyBlok={storyBlok}>
      <HomeHeader siteConfig={homePageData} />
      <FeatureSection siteConfig={homePageData} storyBlok={storyBlok} />
      <MissionSection siteConfig={homePageData} storyBlok={storyBlok} />
      <ProductSection siteConfig={homePageData} storyBlok={storyBlok} />
      <StrengthSection siteConfig={homePageData} storyBlok={storyBlok} />
      <ContactSection siteConfig={homePageData} />
      <BrandSection siteConfig={homePageData} />
      <ContactForm siteConfig={homePageData} storyBlok={storyBlok} />
    </Layout>
  );
};

export default Homepage;
