import React from "react";

export default function InputText({
                                    type,
                                    label,
                                    isShowLabel = true,
                                    isShowError = true,
                                    inputClass = 'block w-full rounded-md px-3.5 py-2 text-black ring-blue placeholder:text-blue bg-sky-blue border-0 focus:ring-2 focus:ring-blue focus:border-blue sm:text-sm sm:leading-6',
                                    id,
                                    errors,
                                    warning,
                                    warningRegex,
                                    input,
                                    defaultValue,
                                    className = "",
                                    isDisabled = false,
                                    placeholder = ''
                                  }) {
  return (
      <div className={className}>

        {type === "textarea" ? (
            <textarea
                id={id}
                rows={4}
                defaultValue={defaultValue}
                disabled={isDisabled}
                className={`block w-full rounded-md px-3.5 py-2 text-black ring-blue placeholder:text-blue bg-sky-blue border-0 focus:ring-2 focus:ring-blue focus:border-blue sm:text-sm sm:leading-6 ${
                    errors
                        ? "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                        : ""
                    }`}
                placeholder={placeholder}
                {...input}
            />
        ) : (
            <input
                type={type}
                id={id}
                defaultValue={defaultValue}
                disabled={isDisabled}
                className={inputClass+` ${
                    errors
                        ? "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                        : ""
                    }`}
                placeholder={placeholder}
                {...input}
            />
        )}

          {
              isShowError && (
                  <>
                      {errors && errors.type === "required" && (
                          <p className="mt-2 text-sm text-red-600">
                              {warning}
                          </p>
                      )}
                      {errors && errors.type === "validate" && (
                          <p className="mt-2 text-sm text-red-600">
                              {warning}
                          </p>
                      )}
                  </>
              )
          }


      </div>
  );
}
