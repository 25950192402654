import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

import { Autoplay, FreeMode, Mousewheel, Navigation } from "swiper/modules";
import { Button } from "./Button";
import { useEffect, useRef } from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/free-mode";
import { motion } from "framer-motion";

export default function ProductSection({ siteConfig, storyBlok }) {
  const productSection = siteConfig?.content?.ProductSection[0];
  const images = productSection?.images || [];

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const swiperRef = useRef(null);
  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.swiper.autoplay.start();
    }
  }, []);
  // Mappa le immagini
  const products = images.map((image, index) => ({
    id: index + 1,
    href: "#",
    imageSrc: image.filename,
    title: image.title,
  }));
  const animationText = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        type: "tween",
        ease: "easeInOut",
        duration: 0.8,
      },
    },
  };

  return (
    <div id="products" className="bg-white py-24 ">
      <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl">
        <div className="mx-auto max-w-3xl text-center ">
          <motion.h2
            variants={animationText}
            initial="hidden"
            whileInView="show"
            className="text-3xl md:text-4xl font-lexend font-bold tracking-tight text-gray-900 "
          >
            {productSection?.Headline}
          </motion.h2>
          <motion.div
            variants={animationText}
            initial="hidden"
            whileInView="show"
            className="text-base md:text-lg mt-4 mb-5 font-sans text-black"
          >
            <div
              className=""
              dangerouslySetInnerHTML={{
                __html: storyBlok.richTextResolver.render(
                  productSection?.Text1 ?? ""
                ),
              }}
            />
            <div
              className="pt-4"
              dangerouslySetInnerHTML={{
                __html: storyBlok.richTextResolver.render(
                  productSection?.Text2 ?? ""
                ),
              }}
            />
          </motion.div>
        </div>

        <>
          <Swiper
            slidesPerView={4}
            centeredSlides={true}
            spaceBetween={30}
            grabCursor={true}
            onSwiper={(swiper) => {
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;
              swiper.navigation.init();
              swiper.navigation.update();
            }}
            modules={[Navigation, FreeMode, Mousewheel, Autoplay]}
            mousewheel={true}
            autoplay={{
              delay: 2000, 
              disableOnInteraction: false,
              waitForTransition: false 
            }}
            className="mySwiper lg:max-w-6ixl smoke-effect-white"
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },

              640: {
                slidesPerView: 2,
                spaceBetween: 30,
              },

              1024: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
            }}
          >
            {products.map((product) => (
              <SwiperSlide
                key={product.id}
                className="mb-16 bg-white text-gray-900 rounded-lg"
              >
                <div className="group relative">
                  <div className="h-80 w-full md:h-60 overflow-hidden rounded-2xl bg-gray-200 group-hover:opacity-75 ">
                    <img
                      src={product.imageSrc}
                      alt=""
                      className="h-full w-full object-cover object-center"
                    />
                  </div>
                  <motion.h3
                    variants={animationText}
                    initial="hidden"
                    whileInView="show"
                    className="mt-4 text-blue text-center font-lexend font-bold"
                  >
                    {product.title}
                  </motion.h3>
                </div>
              </SwiperSlide>
            ))}

            <div className="swiper-navigation-container flex justify-center">
              {/* Pulsante precedente */}
              <div ref={prevRef} className="swiper-button-prev ">
                <ChevronLeftIcon className="h-6 w-6 text-blue bg-light-blue2 rounded-full" />
              </div>
              {/* Pulsante successivo */}
              <div ref={nextRef} className="swiper-button-next pl-2">
                <ChevronRightIcon className="h-6 w-6 text-blue bg-light-blue2 rounded-full" />
              </div>
            </div>
          </Swiper>
        </>
        <div className="mt-16 flex items-center justify-center ">
          <div className=" bg-blue-500 inline-flex rounded-2xl border-0 px-10 py-6 text-white bg-blue sm:text-sm sm:leading-6">
            <motion.p
              variants={animationText}
              initial="hidden"
              whileInView="show"
              className=" px-2 mr-4 text-base font-lexend font-bold"
            >
              {" "}
              {productSection?.Text}
            </motion.p>
            <Button
              type="button"
              sectionId="contact-us"
              className="text-blue bg-white px-2.5 py-1 text-xs"
            >
              {productSection?.Button}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
