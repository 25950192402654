import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/autoplay";
import { Mousewheel, Autoplay  } from "swiper/modules";
import { motion } from "framer-motion";

export default function BrandSection({ siteConfig }) {
  const brandSection = siteConfig?.content?.BrandSection[0];
  const brands = brandSection?.images.map((image, index) => ({
    imageUrl: image.filename,
    alt: image.alt || `Brand image ${index + 1}`,
  }));
  const animationText = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        type: "tween",
        ease: "easeInOut",
        duration: 0.8,
      },
    },
  };
  return (
    <div className="py-24 ">
      <motion.h2
      variants={animationText}
      initial="hidden"
      whileInView="show"
      className="pb-10 text-3xl md:text-4xl text-center font-lexend leading-8 text-gray-900">
        {brandSection?.Headline}
      </motion.h2>
      <Swiper
          modules={[Mousewheel, Autoplay]}
          mousewheel={true}
          spaceBetween={50}
          slidesPerView={7}
          autoplay={{
            delay: 2000, 
            disableOnInteraction: false, 
          }}
          breakpoints={{
            320: {
                slidesPerView: 2, 
                spaceBetween: 40, 
            },
          
            768: {
              slidesPerView: 5,
              spaceBetween: 40,
            },
           
            1024: {
              slidesPerView: 6,
              spaceBetween: 50,
            },

            1440: {
              slidesPerView: 7,
              spaceBetween: 60,
            },
          }}
        >

          {brands?.map((brand, index) => (
            <SwiperSlide
             key={index} >
              <img
                className="max-h-24 w-full object-cover rounded-md" // Aumentato max-h-24 per immagini più grandi
                src={brand.imageUrl}
                alt={brand.alt}
                width={158}
                height={48}
              />

           </SwiperSlide>
           ))}
         </Swiper>


    </div>
  );
}
